<template>
  <div>
    <canvas width="1080" height="1080" ref="canvas"></canvas>

    <div class="text-center">
      <a
        download="compartilhar.png"
        href="#"
        @click="download"
        class="btn btn-success"
      >
        Download
      </a>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Canvg from "canvg";

import qproGeneric from "@/assets/qproGeneric";

export default {
  name: "ShareableImage",

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  props: {
    headerText: {
      type: String,
      default: "QuestõesPRO",
    },
    middleText: {
      type: String,
      default: "Venha já estudar!",
    },
    footerText: {
      type: String,
      default: "Acesse já",
    },
    urlText: {
      type: String,
      default: window.location.href,
    },
    background: {
      type: String,
      default: "default",
    },
  },

  mounted() {
    const $canvas = this.$refs.canvas;
    const ctx = $canvas.getContext("2d");

    const v = Canvg.fromString(
      ctx,
      qproGeneric({
        background: this.background,
        username: `@${this.currentUser.username}`,
        avatarUrl: this.currentUser.avatar
          ? this.$getImageUrl(this.currentUser.avatar.originalName)
          : this.$defaultUserAvatar,
        date: new Date(),
      }),
      {
        scaleHeight: 1080,
        ignoreDimensions: true,
        ignoreMouse: true,
        ignoreClear: true,
      }
    );

    v.start();
  },

  methods: {
    download(ev) {
      var image = this.$refs.canvas.toDataURL("image/jpg");
      ev.target.href = image;
    },
  },
};
</script>

<style scoped>
canvas {
  max-width: 500px;
  height: auto !important;
  margin: 0 auto;
}
</style>
