const bgs = {
  default: `<g id="Default">
  <rect fill="#0084FF" width="1080" height="1080"/>
  <text transform="matrix(1 0 0 1 189 338)" fill="#FFFFFF" font-family="'Consolas'" font-size="75">Venha já estudar!</text>
  </g>`,

  danger: `<g id="Wrong">
  <rect display="inline" fill="#EC4561" width="1080" height="1080"/>
  <text transform="matrix(1 0 0 1 275 338)" display="inline" fill="#FFFFFF" font-family="'Consolas'" font-size="75">Errou no treino,</text>
  <text transform="matrix(1 0 0 1 175 398)" display="inline" fill="#FFFFFF" font-family="'Consolas'" font-size="75">mas acertará na prova!</text>
  </g>`,

  success: `<g id="Correct">
  <rect x="1" y="1" display="inline" fill="#02A499" width="1080" height="1080"/>
  <text transform="matrix(1 0 0 1 160 338)" display="inline" fill="#FFFFFF" font-family="'Consolas'" font-size="75">Parabéns, você acertou!</text>
  </g>`,
};

function twoDigits(n) {
  return n < 10 ? `0${n}` : n;
}

function formatDate(date) {
  const dateStr = `${twoDigits(date.getDate())}/${twoDigits(
    date.getMonth() + 1
  )}/${date.getFullYear()}`;
  const timeStr = `${twoDigits(date.getHours())}:${twoDigits(
    date.getMinutes()
  )}`;
  return `${dateStr} ${timeStr}`;
}

export default ({ background, username, date, avatarUrl }) => `
<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="1080px"
height="1080px" viewBox="0 0 1080 1080" enable-background="new 0 0 1080 1080" xml:space="preserve">

${bgs[background] || ""}

<g id="Space">
<rect x="-1" y="451" fill="#FFFFFF" width="1081" height="539"/>
<text transform="matrix(1 0 0 1 55.667 808.3359)" font-family="'Consolas'" font-size="65">${username}</text>
<text transform="matrix(1 0 0 1 55.667 892.668)" font-family="'Consolas'" font-size="60">${formatDate(
  date
)}</text>
</g>
<g id="Avatar">
<g>
 <defs>
   <circle id="SVGID_1_" cx="540" cy="609" r="125"/>
 </defs>
 <clipPath id="SVGID_2_">
   <use xlink:href="#SVGID_1_"  overflow="visible"/>
 </clipPath>
 <g transform="matrix(1 0 0 1 -3.051758e-005 0)" clip-path="url(#SVGID_2_)">

     <image overflow="visible" width="1252" height="1252" xlink:href="${avatarUrl}"  transform="matrix(0.2636 0 0 0.2636 372.4854 443.9805)">
   </image>
 </g>
</g>
</g>
<g id="Base_text">
<text transform="matrix(1 0 0 1 237.3335 141.1821)" fill="#FFFFFF" font-family="'Consolas-Bold'" font-size="100">QuestõesPRO</text>
<text transform="matrix(1 0 0 1 342 1043)" fill="#FFFFFF" font-family="'Consolas'" font-size="40">questoespro.com.br</text>
<path d="M540,487.836"/>
</g>
</svg>`;
